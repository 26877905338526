import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {InputComponent} from './form/input/item.component';
import {CheckboxComponent} from './form/checkbox/checkbox.component';
import {ButtonComponent} from "./ui/button/item";
import {SearchComponent} from './ui/search/search.component';
import {BackComponent} from './ui/back/back.component';
import {SelectDropDownComponent} from './ui/select-dropdown/select-dropdown';
import {ClickOutsideModule} from 'ng-click-outside';
import {LoaderComponent} from './ui/loader/item';
import {NgxPrintModule} from 'ngx-print';
import {KioskItemComponent} from "./kiosk-item/item.component";
import {PopupCommandsComponent} from "./popups/popup-commands/popup-commands.component";
import {PopupConfirmComponent} from "./popups/popup-confirm/popup-confirm.component";
import {MaskitoModule} from "@maskito/angular";
import {legalPersonItemComponent} from "./legal-person-item/item.component";
import {PopupAddPersonComponent} from "./popups/popup-add-person/popup-add-person.component";
import {PopupCalendarComponent} from "./popups/popup-calendar/popup-calendar.component";

@NgModule({
  declarations: [
    InputComponent,
    CheckboxComponent,
    ButtonComponent,
    SearchComponent,
    BackComponent,
    SelectDropDownComponent,
    LoaderComponent,
    KioskItemComponent,
    legalPersonItemComponent,
    PopupCommandsComponent,
    PopupConfirmComponent,
    PopupAddPersonComponent,
    PopupCalendarComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    ClickOutsideModule,
    NgxPrintModule,
    MaskitoModule
  ],
  exports: [
    InputComponent,
    CheckboxComponent,
    ButtonComponent,
    SearchComponent,
    BackComponent,
    SelectDropDownComponent,
    LoaderComponent,
    KioskItemComponent,
    legalPersonItemComponent,
    PopupCommandsComponent,
    PopupConfirmComponent,
    PopupAddPersonComponent,
    PopupCalendarComponent
  ]
})

export class ComponentsModule {
}
