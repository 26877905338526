import {
  Component, EventEmitter,
  Input,
  OnDestroy,
  OnInit, Output
} from '@angular/core';
import {MainComponent} from "../../core/main.component";
import {AppService} from "../../services/app.service";
import {NavigationService} from "../../services/navigation.service";
import {LegalPerson} from "../../entities/legal-persons.entity";
import {KiosksModelService} from "../../models/kiosks.model.service";
import {PopupConfirmComponent} from "../popups/popup-confirm/popup-confirm.component";
import {LegalPersonsModelService} from "../../models/legal-persons.model.service";
import {Kiosk} from "../../entities/kiosk.entity";


@Component({
    selector: 'legal-person-item',
    styleUrls: ['./item.scss'],
    templateUrl: 'item.html',
})

export class legalPersonItemComponent extends MainComponent implements OnDestroy, OnInit {
  @Input() legalPerson: LegalPerson;
  @Output() goToLegalPersonEmit = new EventEmitter<LegalPerson>();

  constructor(
    appService: AppService,
    navigationService: NavigationService,
    private kiosksModelService: KiosksModelService,
    private legalPersonsModelService: LegalPersonsModelService
  ) {
    super(appService, navigationService);
  }

  ngOnInit() {
  }

  OnDestroy(){}

  goToLegalPerson(legalPerson: LegalPerson) {
    this.goToLegalPersonEmit.emit(legalPerson);
  }

  deleteLegalPerson(legalPerson: LegalPerson) {
    const props = {
      title: 'Внимание',
      subtitle: `Вы действительно собираетесь удалить <br> ${legalPerson.name}?`,
      confirmBtn: 'Да',
      closeBtn: 'Отмена'
    };
    this.appService.openModal(PopupConfirmComponent, props, (data) => {
      if (data.action === 'confirm') {
        this.legalPersonsModelService.deleteLegalPerson(legalPerson.id);
      }
    });
  }
}
