import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Location} from '@angular/common';
import {NavController} from '@ionic/angular';
import {filter} from 'rxjs/operators';
import {AppService} from './app.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  router: Router;
  location: Location;
  navController: NavController;
  appService: AppService;
  previousUrl?: any;
  currentUrl?: any;
  entryUrl?: string | null;
  data: any;
  public routeStack = [];

  constructor(
    router: Router,
    location: Location,
    navController: NavController,
    appService: AppService,
  ) {
    this.router = router;
    this.location = location;
    this.navController = navController;
    this.appService = appService;
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url.replace(/^\/+/g, '');
    });
  }

  goAfterLogin(loggedIn: any) {
    if (loggedIn) {
      let entryUrl = this.entryUrl ? this.entryUrl : 'start';

      if (this.appService.pushData) {
        entryUrl = this.appService.pushData.page;
      }

      if (entryUrl.includes('auth')) {
        entryUrl = 'start';
      }

      this.goTo(entryUrl, {}, true);
    } else {
      this.goTo('auth', {}, true);
    }
  }

  public back(fullBack = false) {
    if (this.canGoBackIfNotStartPage()) {
      this.pushToStack(this.currentUrl);
      this.navController.navigateBack('start');
    } else {
      if (!fullBack && this.canGoBack()) {
        this.navController.navigateBack([this.routeStack?.pop()]);
      } else {
        this.navController.navigateBack([this.routeStack[0]]);
        this.routeStack = [];
      }
    }
  }

  canGoBackIfNotStartPage() {
    return !this.isCurrentUrl('start') && this.routeStack?.length === 0;
  }

  canGoBack(index = 1) {
    return this.canGoBackIfNotStartPage() ? true : this.routeStack?.length !== 0;
  }

  goTo(url: string, opt = {}, replaceUrl = false, data = {}) {
    this.data = data;
    this.pushToStack(this.currentUrl, replaceUrl);
    if (replaceUrl) {
      return this.navController.navigateRoot([url], {queryParams: opt, state: data, replaceUrl});
    } else {
      return this.navController.navigateForward([url], {queryParams: opt, state: data, replaceUrl});
    }
  }

  isCurrentUrl(url: string) {
    return this.currentUrl?.includes(url);
  }

  pushToStack(url: string, replaceUrl = false) {
    if (replaceUrl) {
      this.routeStack = [];
    } else {
      if (typeof url !== 'undefined') {
        this.routeStack.push(url);
      }
    }
  }
}
