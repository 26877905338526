import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {AppService} from '../../services/app.service';
import {environment} from '../../../environments/environment';
import { AuthService } from 'src/app/services/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private appService: AppService,
    private authService: AuthService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf(environment.baseUrl) >= 0) {

      const authToken = this.authService.getToken();

      if (authToken) {
        request = request.clone({
          setHeaders: {
            'Authorization': authToken
          }
        });
      }
    }

    return next.handle(request);
  }

}
