import {Component, Input, OnInit} from '@angular/core';
import {NavigationService} from '../../../services/navigation.service';
import {AppService} from '../../../services/app.service';

@Component({
  selector: 'app-back',
  templateUrl: './back.component.html',
  styleUrls: ['./back.component.scss'],
})
export class BackComponent implements OnInit {

  @Input() cancelDefaultAction = false;

  constructor(
    private navigationService: NavigationService,
    private appService: AppService
  ) { }

  ngOnInit() {}

  goBack() {
    if (!this.cancelDefaultAction) {
      this.navigationService.back();
    }
  }

}
