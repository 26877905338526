import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppService} from '../services/app.service';
import {ApiResponse} from "../entities/apiResponse.entity";
import {LegalPerson, LegalShortsPerson} from "../entities/legal-persons.entity";
import {MainModelService} from "../core/mainModel.service";


@Injectable({
  providedIn: 'root'
})
export class LegalPersonsModelService extends MainModelService {
  legalPersons: LegalPerson[];
  legalShortsPersons: LegalShortsPerson[];
  legalPersonsWithEmpty: LegalShortsPerson[] = [];
  legalPersonsToShow: LegalPerson[];

  constructor(
    http: HttpClient,
    appService: AppService
  ) {
    super(http, appService);

    this.model = 'kiosk/company';
  }

  async getLegalPersons() {
    // получение полного списка юр. лиц
    this.getRequest({}, (data: ApiResponse) => {
      if (data.status) {
        this.legalPersons = data.result;
        this.legalPersonsToShow = data.result;
      }
    }, 'all', false, true);
  }

  getShortsLegalPersons() {
    // получение сокращённого списка юр. лиц
    this.getRequest({}, (data: ApiResponse) => {
      if (data.status) {
        this.legalShortsPersons = data.result;
        // legalPersonsWithEmpty и добавление элемента с id 0 нужно для того, чтобы
        // потом в фильтре можно было фильтровать киоски у которых не выбрана компания
        this.legalPersonsWithEmpty = [...this.legalShortsPersons];
        this.legalPersonsWithEmpty.forEach((item)=> {
          item.checked = false;
        });
        this.legalPersonsWithEmpty.push({
          id: '0',
          name: 'Без юр. лица',
          checked: false
        });
      }
    }, 'list', false, true);
  }

  addUpdateLegalPerson(postData: any): Promise<ApiResponse> {
    // изменение юр. лица, если не передавать id, то будет создание
    return new Promise((resolve, reject) => {
      this.postRequest(postData, {}, (data: ApiResponse) => {
        if (data.status) {
          this.getLegalPersons(); // Обновляем список юр. лиц
          resolve(data);
          this.appService.showToast('Готово!', 'Операция прошла успешно', 'success');
        } else {
          reject(data);
          this.appService.showToast('Ошибка', 'Операция не удалась', 'danger')
        }
      }, 'save', false, true);
    });
  }

  deleteLegalPerson(legalPersonId) {
    // удаление юр. лица
    const postData = {
      id: legalPersonId
    };
    this.postRequest(postData, {}, (data: ApiResponse) => {
      if (data.status) {
        this.getLegalPersons(); // Обновляем список юр. лиц
        this.appService.showToast('Готово!', 'Операция прошла успешно', 'success');
      } else {
        // если у юр. лица уже привязан киоск, то её нельзя удалить
        this.appService.showAlert('', data.message, false);
        // this.appService.showToast('Ошибка', 'Операция не удалась', 'danger')
      }
    }, 'remove', false, true);
  }
}
