import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  @Input() items: any = [];
  @Output() searchResults = new EventEmitter<{ results: any[], term: string, valueBySearch: string }>();
  // параметр по которому будет осуществляться поиск
  @Input() valueBySearch = '';
  @Input() menuSearch = false;
  @Input() selectSearch = false;
  @Input() filter = false;
  @Input() maskito: any;
  @Input() placeholder: '';
  @Input() maxWidthText = 'auto';
  @Input() searchTerm: any = '';
  showResults = false;
  searchItems: any = [];
  noResult = false;
  focusInput = false;

  constructor() { }

  ngOnInit() {}

  onSearch(event?: KeyboardEvent) {
    if (this.selectSearch) {
      this.searchLogic();
    } else {
      // если была нажата клавиша Enter
      if (event?.keyCode === 13) {
        this.showResults = false;
        this.searchLogic();
      }
    }
  }

  searchLogic() {
    if (!this.searchTerm.length) {
      this.searchResults.emit(this.items);
    } else {
      const items = this.items;
      // console.log('this.convertToFlatArray(items)', this.convertToFlatArray(items));
      this.searchResults.emit({results: items.filter(item =>
          item[this.valueBySearch].toLowerCase().includes(this.searchTerm.toLowerCase())
        ), term: this.searchTerm, valueBySearch: this.valueBySearch});
    }
  }

  changeInput(event: any) {
    // console.log('event', event);
    this.noResult = false;
    if (event?.length) {
      const items = this.items;
      if (this.selectSearch) {
        //если selectSearch true эмит сразу
        this.searchResults.emit({results: items.filter((item: any) =>
            item[this.valueBySearch].toLowerCase().includes(this.searchTerm.toLowerCase())
          ), term: this.searchTerm, valueBySearch: this.valueBySearch});
        this.searchItems = items.filter(item =>
          item[this.valueBySearch].toLowerCase().includes(this.searchTerm.toLowerCase())
        );
      } else {
        this.searchItems = items.filter((item: any) =>
            item[this.valueBySearch].toLowerCase().includes(this.searchTerm.toLowerCase())
          // && item.permissions.includes(this.authService.getToken().role)
        );
      }
      // console.log('this.searchItems', this.searchItems);
      this.searchResults.emit({results: this.searchItems, term: this.searchTerm, valueBySearch: this.valueBySearch});
      if (!this.searchItems.length) {
        this.showResults = false;
        this.noResult = true;
      } else {
        this.showResults = true;
        this.noResult = false;
      }
    } else {
      this.removeSearch();
    }
  }

  changValue(item: any) {
    if (item.dropdownItems) {
      // если у элемента есть свой выпадающий список,
      // то при клике на него переход по ссылке не требуется
      this.searchItems = [];
      this.showResults = false;
      this.searchTerm = item.name;
      const items = this.items;
      this.searchResults.emit({results: items.filter((item: any) =>
          item[this.valueBySearch].toLowerCase().includes(this.searchTerm.toLowerCase())
        ), term: this.searchTerm, valueBySearch: this.valueBySearch});
    } else {
      // this.navigationService.goTo(item.link);
      this.removeSearch();
    }
  }

  focusin() {
    this.focusInput = true;
  }

  focusout() {
    this.focusInput = false;
  }

  removeSearch() {
    this.searchTerm = '';
    this.showResults = false;
    this.noResult = false;
    this.searchItems = [];
    this.searchResults.emit({results: this.items, term: this.searchTerm, valueBySearch: this.valueBySearch});
  }
}
