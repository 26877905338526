import {Component, Input, OnInit} from '@angular/core';
import {AppService} from "../../../services/app.service";
import {CommandsModelService} from "../../../models/commands.model.service";


@Component({
    selector: 'app-popup-confirm',
    templateUrl: './popup-confirm.component.html',
    styleUrls: ['./popup-confirm.component.scss'],
})
export class PopupConfirmComponent implements OnInit {
  @Input() title;
  @Input() subtitle;
  @Input() confirmBtn = 'Да';
  @Input() closeBtn = 'Отмена';

  appService: AppService;
  commandsModelService: CommandsModelService;

  constructor(
    appService: AppService,
    commandsModelService: CommandsModelService,
  ) {
    this.appService = appService;
    this.commandsModelService = commandsModelService;
  }

  ngOnInit() {
  }

  close() {
    this.appService.closeModal();
  }

  confirm() {
    this.appService.confirmModal();
  }
}
