import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ApiResponse} from '../entities/apiResponse.entity';
import {environment} from 'src/environments/environment';
import {NavigationService} from './navigation.service';
import {AppService} from './app.service';
import {CommandsModelService} from "../models/commands.model.service";
import {KiosksModelService} from "../models/kiosks.model.service";
import {catchError} from "rxjs/operators";
import {Observable, ObservableInput, throwError} from "rxjs";
import {LegalPersonsModelService} from "../models/legal-persons.model.service";

@Injectable({ providedIn: 'root' })
export class AuthService {
  legalPersonsModelService: LegalPersonsModelService;
  private httpClient: HttpClient;

  constructor(
    httpClient: HttpClient,
    private navigationService: NavigationService,
    private appService: AppService,
    private commandsModelService: CommandsModelService,
    private kiosksModelService: KiosksModelService,
    legalPersonsModelService: LegalPersonsModelService
  ) {
      this.httpClient = httpClient;
      this.legalPersonsModelService = legalPersonsModelService;
  }

  public getToken() {
      return localStorage.getItem('appAuthToken');
  }

  isLogin(): boolean {
      return !!localStorage.getItem('appAuthToken');
  }

  public setToken(token): void {
      localStorage.setItem('appAuthToken', token);
  }

  public logout() {
    this.commandsModelService.kiosksSelected = [];
    this.commandsModelService.commandsSelected = [];
    this.kiosksModelService.kiosksItems?.forEach(kiosk => kiosk.checked = false);
    this.kiosksModelService.kiosksItemsToShow?.forEach(kiosk => kiosk.checked = false);
    localStorage.removeItem('appAuthToken');
    this.navigationService.goTo('auth');
    this.kiosksModelService.stopHeartBeat();
  }

  public authorize(
      login: string,
      password: string,
      onOk: (response: ApiResponse) => void,
      onFailed: (error: HttpErrorResponse) => void = null
  ): void {
      this.httpClient.post<ApiResponse>(this.getUrl(), { login, password })
      .pipe(catchError(
          (error: any, caught: Observable<ApiResponse>): ObservableInput<ApiResponse> => {
              onFailed(error);

                return throwError(error);
            }
        ))
        .subscribe(response => {
            if ( response ) {
              onOk(response);
              if (response.status) {
                this.setToken(response.result.token);
                this.kiosksModelService.getKiosks();
                this.legalPersonsModelService.getLegalPersons();
                this.legalPersonsModelService.getShortsLegalPersons();
              }
            } else {
                if ( typeof onFailed === 'function' ) {
                    onFailed((<any>response));
                }
            }
        });
    }

  private getUrl(): string {
      return environment.baseUrl + environment.apiUrl + 'kiosk/authMonitoring';
  }
}
