import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppService} from '../services/app.service';
import {ApiResponse} from "../entities/apiResponse.entity";
import {Kiosk} from "../entities/kiosk.entity";
import {MainModelService} from "../core/mainModel.service";
import {KiosksModelService} from "./kiosks.model.service";

@Injectable({
  providedIn: 'root'
})
export class CommandsModelService extends MainModelService {
  kiosksSelected: string[] = [];
  commandsSelected = [];
  kioskId: string = '';

  constructor(
    http: HttpClient,
    appService: AppService,
    private kiosksModelService: KiosksModelService
  ) {
    super(http, appService);

    this.model = 'kiosk';
  }

  // метод для добавления киоска в массив kiosksSelected
  addSelectedKiosk(kiosk: Kiosk) {
    this.kiosksSelected.push(kiosk.kiosk_id);
  }

  // метод для удаления киоска из массива kiosksSelected
  removeSelectedKiosk(kiosk: Kiosk) {
    const index = this.kiosksSelected.indexOf(kiosk.kiosk_id);
    if (index !== -1) {
      this.kiosksSelected.splice(index, 1);
    }
  }

  async sendCommands(setSettings: any = {}, customCommandValue = '', windowsCommands = false) {
    this.appService.closeModal();
    let data: any = {};
    if (windowsCommands) {
      data.ip_commands = this.commandsSelected
    } else {
      data.commands = this.commandsSelected;
    }

    if (this.kioskId) {
      data.kioskId = this.kioskId;
    } else {
      data.kioskIdList = this.kiosksSelected;
    }

    this.commandsSelected.forEach((command)=> {
      if (command.name === 'SetSettings') {
        command.data = setSettings;
      }
      if (command.name === 'Restart kiosk') {
        command.data = 'Taskkill /IM kiosk.exe /F && cd %AppData%\\Microsoft\\Windows\\Start Menu\\Programs && start kiosk.lnk';
      }
      if (command.name === 'Restart kiosk Browser') {
        command.data = 'Taskkill /IM Firefox.exe /F && cd %AppData%\\Microsoft\\Windows\\Start Menu\\Programs && start kiosk.lnk';
      }
      if (command.name === 'Reboot') {
        command.data = 'shutdown /r';
      }
      if (command.name === 'Custom Command') {
        command.data = customCommandValue;
      }
    });

    this.postRequest(data, {},(data: ApiResponse) => {
      if (data && data.status) {
        this.appService.showToast('Успешно!', 'Команды отправлены', 'success');
        this.kiosksSelected = [];
        this.commandsSelected = [];
        this.kioskId = '';
      } else {
        this.appService.showToast('Ошибка', 'Команды не отправлены', 'danger')
      }
    }, windowsCommands ? 'saveIPCommands' : 'saveCommands', false, true);
  }

}
