import {Component, Input, OnInit} from '@angular/core';
import {AppService} from "../../../services/app.service";
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {LegalPerson} from "../../../entities/legal-persons.entity";
import {LegalPersonsModelService} from "../../../models/legal-persons.model.service";
import {ApiResponse} from "../../../entities/apiResponse.entity";


@Component({
    selector: 'app-popup-commands',
    templateUrl: './popup-add-person.component.html',
    styleUrls: ['./popup-add-person.component.scss'],
})
export class PopupAddPersonComponent implements OnInit {
  @Input() legalPerson: LegalPerson;
  appService: AppService;
  legalPersonsModelService: LegalPersonsModelService;
  // форма
  legalPersonForm = new FormGroup({
    name : new FormControl('', [Validators.required]),
    ki_company_id: new FormControl('', [Validators.required]),
    ki_login: new FormControl('', [Validators.required]),
    ki_password: new FormControl('', [Validators.required]),
    sbp_login: new FormControl(''),
    sbp_password: new FormControl(''),
    iiko_url: new FormControl('', [Validators.required, this.urlValidator()]),
    iiko_login: new FormControl(''),
    iiko_password: new FormControl('', [Validators.required]),
  });
  urlValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;

      // Проверяем только если поле не пустое
      if (value && typeof value === 'string') {
        const isValid = /^https?:\/\/\S+$/.test(value);
        return isValid ? null : { 'invalidUrl': { value } };
      }

      // Если поле пустое, считаем его валидным
      return null;
    };
  }
  constructor(
    appService: AppService,
    legalPersonsModelService: LegalPersonsModelService
  ) {
    this.appService = appService;
    this.legalPersonsModelService = legalPersonsModelService;
  }

  ngOnInit() {
    if (this.legalPerson) {
    }
  }

  close() {
    this.appService.closeModal();
  }

  createNewLegalPerson() {
    const postData = {
      data: this.legalPersonForm.value
    };

    this.legalPersonsModelService.addUpdateLegalPerson(postData)
      .then((response: ApiResponse) => {
        // успешный ответ здесь
        this.appService.confirmModal();
      })
      .catch((error: ApiResponse) => {
      });
  }
}
