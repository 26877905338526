import {Component, Input, OnInit} from '@angular/core';
import {AppService} from "../../../services/app.service";


@Component({
    selector: 'app-popup-calendar',
    templateUrl: './popup-calendar.component.html',
    styleUrls: ['./popup-calendar.component.scss'],
})
export class PopupCalendarComponent implements OnInit {
  appService: AppService;
  @Input() from = '';
  @Input() to = '';
  @Input() type = '';

  constructor(
    appService: AppService
  ) {
    this.appService = appService;
  }

  ngOnInit() {
    // console.log('0', this.type);
    // console.log('1', this.from);
    // console.log('2', this.to);
  }

  close() {
    this.appService.closeModal();
  }

  sendCalendar() {
    const data = {
      from: this.from,
      to: this.to,
    };
    // console.log('data', data);
    this.appService.closeModal(data);
  }
}
