import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-select-dropdown',
  templateUrl: './select-dropdown.component.html',
  styleUrls: ['./select-dropdown.component.scss'],
})
export class SelectDropDownComponent implements OnInit {
  @Input() items: any;
  @Input() itemSelected: any;
  @Input() search = false;
  @Input() isMultiple = false;
  @Input() isFilter = false;

  @Input() placeholder = 'Select option';
  @Input() maxWidthSelectText = 'auto';
  @Input() color = '';
  @Input() versions = false;
  @Input() slug = '';

  @Output() itemSelect = new EventEmitter<{ results: any[], valueBySelect?: string }>();
  @Output() itemSelectedItems = new EventEmitter<any[]>();
  @Output() changeSelect = new EventEmitter<boolean>();
  itemsToShow: any;
  updateFiltersTimer: any = 0;
  updateFiltersDelay = 3000; // 3000 миллисекунд (3 секунд)

  public open = false;
  private hasCheckboxChanges = false; // Флаг для отслеживания изменений чекбоксов

  constructor() { }

  ngOnInit() {
    this.sortItems();
    if (this.isMultiple) {
      this.sortItemsByChecked(this.itemsToShow);
      this.itemSelected = [];
      this.selectTitleUpdate();
    } else {
      // выбранный элемент на основе itemSelected
      const id = this.itemSelected?.toString();
      const item = this.findItemById(this.items, id);
      if (item) {
        this.onItemChange(item);
      }
    }
  }

  // Метод для поиска элемента по id в иерархическом списке
  findItemById(items: any[], id: any): any {
    for (const item of items) {
      if (item.id === id) {
        return item;
      }
      if (item.childs && item.childs.length) {
        const foundItem = this.findItemById(item.childs, id);
        if (foundItem) {
          return foundItem;
        }
      }
    }
    return null;
  }

  toggleItem(item: any): void {
    if (item.childs && item.childs.length) {
      item.opened = !item.opened;
    } else {
      this.onItemChange(item);
    }
  }

  returnItems(items?: any) {
    if (items?.results) {
      this.itemsToShow = items?.results;
    }
  }

  onLabelClick(): void {
    this.open = !this.open;
    if (!this.open) {
      if (this.hasCheckboxChanges) {
        this.updateFilters(true);
        // После обновления сбрасываем флаг изменений чекбоксов
        this.hasCheckboxChanges = false;
      }
    } else {
      // При открытии селекта сбрасываем флаг изменений чекбоксов
      this.hasCheckboxChanges = false;
    }
  }

  updateFilters(sort: boolean) {
    clearTimeout(this.updateFiltersTimer);
    // запрос на обновление фильтра
    this.changeSelect.emit(true);
    // и делается пересортировка, если нужно
    if (sort) {
      if (this.convertToFlatArray(this.items).filter((el: any) => el.checked).length > 0) {
        // чтобы элементы с checked true были сверху
        this.sortItemsByChecked(this.itemsToShow);
      } else {
        // иначе - обычная сортировка
        this.sortItems();
      }
    }
  }

  startUpdateFiltersTimer() {
    clearTimeout(this.updateFiltersTimer);
    this.updateFiltersTimer = setTimeout(() => {
      if (this.hasCheckboxChanges) {
        this.updateFilters(false);
        this.hasCheckboxChanges = false;
      }
    }, this.updateFiltersDelay);
  }

  closeDropDownMenu() {
    if (this.open) {
      if (this.hasCheckboxChanges) {
        this.updateFilters(true);
      }
    }
    this.open = false;
  }

  sortItemsByChecked(items: any[]): void {
    items.sort((a, b) => b.checked - a.checked);

    items.forEach(item => {
      if (item.childs && item.childs.length) {
        this.sortItemsByChecked(item.childs);
      }
    });
  }

  getSelectedItemsText(): string {
    if (this.isMultiple) {
      const selectedItems = this.itemSelected;
      const maxLength = 30; // Максимальная ширина
      let text = selectedItems.join(', ');
      if (text.length > maxLength) {
        text = text.substring(0, maxLength - 3) + '...';
      }
      return text;
    }
  }

  checkIsMultiple(item: any) {
    if (!this.isMultiple) {
      this.onItemChange(item);
    }
  }

  convertToFlatArray(items: any[]): any[] {
    return items.reduce((flatArray, item) => {
      const nestedItems = item.childs ? this.convertToFlatArray(item.childs) : [];
      return [...flatArray, {
        checked: item.checked,
        childs: item.childs,
        id: item.id,
        opened: item.opened,
        parentId: item.parentId,
        name: item.name
      }, ...nestedItems];
    }, []);
  }

  // эта часть проставляет checked родителям
  async updateParentsTrue(items) {
    if (items && items.length) {
      const promises = items.map(async (item) => {
        if (item.childs && item.childs.length) {
          const filteredChildren = await Promise.all(
            item.childs.map(child => this.isChildChecked(child))
          );

          if (filteredChildren.some(childChecked => childChecked)) {
            item.checked = true;
          }

          await this.updateParentsTrue(item.childs);
        }
      });

      // Дожидаемся завершения всех асинхронных операций
      await Promise.all(promises);
    }
  }

  async isChildChecked(item) {
    if (item.childs && item.childs.length) {
      const filteredChildren = await Promise.all(
        item.childs.map(child => this.isChildChecked(child))
      );
      return filteredChildren.some(childChecked => childChecked);
    }
    return item.checked;
  }

  // Обновление родительских элементов в зависимости от состояния детей
  updateParentsFalse(items) {
    // Если checked установлен в false
    items?.forEach((item) => {
      // Проверяем, есть ли у элемента дочерние элементы и все они unchecked
      if (item.childs && item.childs.length && item.childs.filter((el: any) => el.checked).length === 0) {
        item.checked = false; // Устанавливаем checked родителю в false
      } else {
        this.updateParentsFalse(item.childs); // Рекурсивно вызываем для родительских элементов
      }
    });
  }

  updateItem(items, item) {
    // console.log('items', items);
    items.forEach((el) => {
      if (el.id === item.id) {
        el.checked = item.checked; // Обновляем элементы в плоском массиве
      }
      if (el.childs && el.childs.length) {
        this.updateItem(el.childs, item);
      }
    });
  }

  // Обработчик изменения состояния элемента (вызывается при клике на элемент)
  async onItemChange(item: any) {
    if (this.isMultiple) {
      this.hasCheckboxChanges = true; // Устанавливаем флаг изменений чекбоксов
      item.checked = !item.checked; // Инвертируем состояние элемента
      await this.updateItem(this.items, item);
      await this.toggleItemRecursive(item, item.checked); // Вызываем рекурсивную функцию для проставления чекбоксов
      if (item.checked) {
        await this.updateParentsTrue(this.items);
      } else {
        await this.updateParentsFalse(this.items); // Обновляем родителей
      }
      await this.selectTitleUpdate(); // Обновляем выбранные элементы для отображения
      // Очищаем предыдущий таймер (если он был) и запускаем новый
      // эта логика нужна для того, чтобы обновление фильтров происходило
      // если были изменения чекбоксов и прошло более 3 секунд
      clearTimeout(this.updateFiltersTimer);
      this.startUpdateFiltersTimer();
      this.itemSelect.emit({results:item, valueBySelect: this.slug}); // Отправляем выбранный элемент через EventEmitter
    } else {
      this.itemSelected = item; // Устанавливаем выбранный элемент
      this.itemSelect.emit({results:item, valueBySelect: this.slug}); // Отправляем выбранный элемент через EventEmitter
      this.open = false; // Закрываем выпадающее меню
    }
  }

  // Рекурсивное проставление чекбоксов для всех дочерних элементов
  toggleItemRecursive(item: any, checked: boolean): void {
    item.checked = checked; // Устанавливаем состояние элемента

    if (item.childs && item.childs.length) {
      item.childs.forEach((childItem: any) => {
        this.toggleItemRecursive(childItem, checked); // Рекурсивный вызов для дочерних элементов
      });
    }
  }

  // Обновление выбранных элементов для отображения
  selectTitleUpdate() {
    setTimeout(() => {
      // Обновляем itemSelected на основе элементов с установленным checked в true
      this.itemSelected = this.convertToFlatArray(this.items).filter((obj) => obj.checked).map((obj) => obj.name);
    }, 100);
  }

  pickAll() {
    this.itemSelected = [];

    const pickCheckedRecursive = (items: any[]) => {
      items.forEach(item => {
        item.checked = true;
        this.itemSelect.emit({results:item, valueBySelect: this.slug});
        if (item.childs && item.childs.length) {
          pickCheckedRecursive(item.childs); // Рекурсивно обрабатываем вложенные элементы
        }
      });
    };

    pickCheckedRecursive(this.items);
    this.selectTitleUpdate();
    this.sortItems();
  }

  clearAll() {
    this.itemSelected = [];

    const clearCheckedRecursive = (items: any[]) => {
      items.forEach(item => {
        item.checked = false;
        this.itemSelect.emit({results:item, valueBySelect: this.slug});
        if (item.childs && item.childs.length) {
          clearCheckedRecursive(item.childs); // Рекурсивно обрабатываем вложенные элементы
        }
      });
    };
    this.selectTitleUpdate();
    clearCheckedRecursive(this.items);
    this.sortItems();
  }

  sortItems() {
    const isYearArray = this.items.some(item => item.titnamele === '2023');
    if (isYearArray) {
      this.itemsToShow = this.items.sort((a, b) => a.name.toString().localeCompare(b.name.toString())).reverse();
    } else {
      this.itemsToShow = this.items.sort((a, b) => a.id - b.id);
    }
  }

  notAllChecked(item: any) {
    // Проверяем наличие вложенных элементов
    if (item.childs && item.childs.length) {
      // Проверяем, есть ли хотя бы один вложенный элемент с checked true
      const hasCheckedChild = item.childs.some(childItem => childItem.checked);
      const hasUncheckedChild = item.childs.some(childItem => !childItem.checked);

      if (!hasCheckedChild) {
        // Если нет вложенных элементов с checked true, проставляем текущему элементу checked равным false
        item.checked = false;
        return false; // Возвращаем false, чтобы отметить, что все вложенные элементы имеют checked false
      } else if (hasUncheckedChild) {
        // Если есть вложенный элемент с checked false, проставляем текущему элементу notAllChecked равным true
        item.checked = true; // Добавляем эту строку для проставления checked true у родителя, если есть вложенный элемент с checked false
        return true;
      }

      // Проверяем, есть ли хотя бы один вложенный элемент с checked false и сам элемент имеет checked true
      return item.childs.some(childItem => !childItem.checked) && item.checked;
    }

    return false; // Если нет вложенных элементов, возвращаем false
  }
}
