import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: 'start',
    loadChildren: () => import('./pages/start/page.module').then(m => m.StartPageModule),
    canActivateChild: [AuthGuard]
  },
  {
    path: 'closed',
    loadChildren: () => import('./pages/start/page.module').then(m => m.StartPageModule),
    canActivateChild: [AuthGuard]
  },
  {
    path: 'history',
    loadChildren: () => import('./pages/history/page.module').then(m => m.HistoryPageModule),
    canActivateChild: [AuthGuard]
  },
  {
    path: 'legal-persons',
    loadChildren: () => import('./pages/legal-persons/page.module').then(m => m.LegalPersonsPageModule),
    canActivateChild: [AuthGuard]
  },
  {
    path: 'kiosk/:kioskId',
    loadChildren: () => import('./pages/kiosk/page.module').then(m => m.KioskPageModule),
    canActivateChild: [AuthGuard]
  },
  {
    path: 'legal-persons/:legalPersonId',
    loadChildren: () => import('./pages/legal-person/page.module').then(m => m.LegalPersonPageModule),
    canActivateChild: [AuthGuard]
  },
  {
    path: 'kiosk/:kioskId/logs',
    loadChildren: () => import('./pages/logs/page.module').then(m => m.LogsPageModule),
    canActivateChild: [AuthGuard]
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/sign-in/page.module').then(m => m.AuthPageModule),
    canActivateChild: []
  },
  // {
  //   path: 'sign-up',
  //   loadChildren: () => import('./pages/auth/sign-up/page.module').then(m => m.SignUpPageModule)
  // },
  {
    path: '',
    redirectTo: 'start',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
