import {Injectable, OnDestroy} from '@angular/core';
import {CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {HelperService} from '../../services/helper.service';
import {AppService} from '../../services/app.service';
import {NavigationService} from '../../services/navigation.service';
import {AuthService} from '../../services/auth.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivateChild {
  appService: AppService;
  authService: AuthService;
  helperService: HelperService;
  navigationService: NavigationService;
  router: Router;

  // @TODO merge two guards

  constructor(
    appService: AppService,
    helperService: HelperService,
    authService: AuthService,
    navigationService: NavigationService,
    router: Router
  ) {
    this.appService = appService;
    this.authService = authService;
    this.helperService = helperService;
    this.navigationService = navigationService;
    this.router = router;
  }

  async canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree | any> {
    if (this.authService.isLogin()) {
      return true;
    } else {
      return this.router.parseUrl('auth');
    }
  }
}
