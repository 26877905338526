import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
  @Input() isChecked: boolean;
  @Input() whiteBg: boolean = true;
  @Input() labelText;
  @Input() labelColor = '#1D1D1D';
  @Output() checkboxEvent = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onCheckboxEvent(){
    this.isChecked = !this.isChecked;
    this.checkboxEvent.emit(this.isChecked);
  }
}
