import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppService} from '../services/app.service';
import {MainModelService} from "../core/mainModel.service";
import {FilterParams, HistoryItem} from "../entities/history.entity";
import {from, Observable} from "rxjs";
import {ApiResponse} from "../entities/apiResponse.entity";

@Injectable({
  providedIn: 'root'
})
export class HistoryModelService extends MainModelService {
  historyItems: HistoryItem[];

  constructor(
    http: HttpClient,
    appService: AppService
  ) {
    super(http, appService);

    this.model = 'kiosk/history';
  }

  getHistory(page: number, pageSize: number, filterParams: FilterParams): Observable<HistoryItem[]>  {
    const requestData = {
      page: page,
      pageSize: pageSize,
      filter: {
        kiosk_id: filterParams.kiosk_id,
        date_from: filterParams.date_from,
        date_to: filterParams.date_to,
        action: filterParams.action,
        author_id: filterParams.author?.id
      }
    };

    return from(this.postRequest(requestData, {}, (data: ApiResponse) => {
      // console.log('data', data);
      if (data) {
        this.historyItems = data.result;
        this.historyItems.sort((a, b) => {
          const dateA = new Date(a.date_time).getTime();
          const dateB = new Date(b.date_time).getTime();
          return dateB - dateA;
        });
      }
    }, 'list', false, false).then(() => {
      return this.historyItems;
    }));
  }
}
