import {Component, Input, OnInit} from '@angular/core';
import {AppService} from "../../../services/app.service";
import {CommandsModelService} from "../../../models/commands.model.service";
import {KiosksModelService} from "../../../models/kiosks.model.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";


@Component({
    selector: 'app-popup-commands',
    templateUrl: './popup-commands.component.html',
    styleUrls: ['./popup-commands.component.scss'],
})
export class PopupCommandsComponent implements OnInit {
  @Input() commands;
  @Input() windowsCommands: boolean = false;
  @Input() kiosk;
  appService: AppService;
  commandsModelService: CommandsModelService;
  setSettings = false;
  customCommand = false;
  customCommandValue = '';
  // форма
  commandForm = new FormGroup({
    blocked: new FormControl(),
    deviceId: new FormControl(''),
    kkmUrl: new FormControl('' ),
    kioskId : new FormControl(''),
    testMode : new FormControl(false),
  });
  constructor(
    appService: AppService,
    commandsModelService: CommandsModelService,
    private kiosksModelService: KiosksModelService
  ) {
    this.appService = appService;
    this.commandsModelService = commandsModelService;
  }

  ngOnInit() {
    if (this.kiosk) {
      this.commandsModelService.kioskId = this.kiosk.kiosk_id;
      this.commandForm.patchValue({
        blocked: this.kiosk.config?.blocked,
        deviceId: this.kiosk.config?.deviceId,
        kkmUrl: this.kiosk.config?.kkmUrl,
        kioskId: this.kiosk.kiosk_id,
        testMode: this.kiosk.config?.testMode,
      });
    }
  }

  close() {
    this.appService.closeModal();
  }

  sendCommands() {
    this.commandsModelService.sendCommands(this.commandForm.value, this.customCommandValue, this.windowsCommands).then(()=> {
      this.unChecked();
    });
  }

  unChecked() {
    this.kiosksModelService.kiosksItems?.forEach(kiosk => kiosk.checked = false);
    this.kiosksModelService.kiosksItemsToShow?.forEach(kiosk => kiosk.checked = false);
  }

  handleCheckboxEvent(command: any) {
    command.checked = !command.checked;
    const selectedCommand = {
      data: null,
      name: command.value
    };

    // console.log('command', command);

    if (command.value === 'SetSettings') {
      this.setSettings = !this.setSettings;
    }

    if (command.value === 'Custom Command') {
      this.customCommand = !this.customCommand;
    }

    if (command.checked) {
      if (command.value === 'unblock') {
        const blockAllIndex = this.commands.findIndex(item => item.value === 'block');
        this.commands[blockAllIndex].checked = false;
        // Найти индекс элемента в массиве по значению value
        const index = this.commandsModelService.commandsSelected.findIndex((item) => item.name === 'block');

        if (index !== -1) {
          // Удалить элемент из массива по индексу
          this.commandsModelService.commandsSelected.splice(index, 1);
        }
      }
      if (command.value === 'block') {
        const unblockAllIndex = this.commands.findIndex(item => item.value === 'unblock');
        this.commands[unblockAllIndex].checked = false;
        // Найти индекс элемента в массиве по значению value
        const index = this.commandsModelService.commandsSelected.findIndex((item) => item.name === 'unblock');

        if (index !== -1) {
          // Удалить элемент из массива по индексу
          this.commandsModelService.commandsSelected.splice(index, 1);
        }
      }
      this.commandsModelService.commandsSelected.push(selectedCommand); // Добавить команду в массив выбранных
    } else {
      // Найти индекс элемента в массиве по значению name
      const index = this.commandsModelService.commandsSelected.findIndex((item) => item.name === selectedCommand.name);

      if (index !== -1) {
        // Удалить элемент из массива по индексу
        this.commandsModelService.commandsSelected.splice(index, 1);
      }
    }
  }
}
