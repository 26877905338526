import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy, Output, EventEmitter
} from '@angular/core';
import {MainComponent} from "../../core/main.component";
import {AppService} from "../../services/app.service";
import {NavigationService} from "../../services/navigation.service";
import {Kiosk} from "../../entities/kiosk.entity";
import {PopupCommandsComponent} from "../popups/popup-commands/popup-commands.component";
import {PopupConfirmComponent} from "../popups/popup-confirm/popup-confirm.component";
import {KiosksModelService} from "../../models/kiosks.model.service";

@Component({
    selector: 'kiosk-item',
    styleUrls: ['./item.scss'],
    templateUrl: 'item.html',
})

export class KioskItemComponent extends MainComponent implements OnDestroy, OnInit {
  @Input() kiosk: Kiosk;
  @Output() goToKioskEmit = new EventEmitter<Kiosk>();
  @Output() checkboxChangeEvent = new EventEmitter<Kiosk>();
  constructor(
    appService: AppService,
    navigationService: NavigationService,
    private kiosksModelService: KiosksModelService,
  ) {
    super(appService, navigationService);
  }

  ngOnInit() {
  }

  OnDestroy(){}

  goToKiosk(kiosk: Kiosk) {
    this.goToKioskEmit.emit(kiosk);
  }

  handleCheckboxEvent(checked: boolean) {
    this.kiosk.checked = checked;
    this.checkboxChangeEvent.emit(this.kiosk); // Отправляем событие изменения чекбокса
  }

  deleteKiosk(kiosk: Kiosk) {
    const props = {
      title: 'Внимание',
      subtitle: `Вы действительно собираетесь удалить <br> ${kiosk.kiosk_id}?`,
      confirmBtn: 'Да',
      closeBtn: 'Отмена'
    };
    this.appService.openModal(PopupConfirmComponent, props, (data) => {
      if (data.action === 'confirm') {
        this.kiosksModelService.deleteKiosk(kiosk.kiosk_id);
      }
    });
  }
}
